<template>
  <b-overlay :show="isLoading" rounded="sm">
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">Trainings</span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">
            {{ totalRows }} en total
          </span>
        </h3>
        <div class="card-toolbar">
          <a
            @click="goNewItem"
            class="btn btn-primary font-weight-bolder font-size-sm mr-3"
          >
            Nuevo training
          </a>
        </div>
      </div>
      <div class="card-body pb-10" data-app>
        <v-text-field
                v-model="dtSearch"
                label="Buscar"
                append-icon="search"
                class="mx-4"
                single-line
                hide-details
        ></v-text-field>
        <v-data-table
          :headers="headers"
          :items="list"
          :server-items-length="totalRows"
          :options.sync="options"
          :loading="isLoadingTable"
          :footer-props="{
              itemsPerPageOptions: [5, 10, 25, 50],
              showFirstLastPage: true,
              itemsPerPageText: 'Resultados por página'
          }"
          :items-per-page="itemsPerPage"
          class="elevation-1"
          locale="es"
          ref="dtTrainings"
        >
          <template v-slot:item.image="{ item }">
            <div class="text-center">
              <img
                :src="item.image"
                class="image-table medium align-self-center"
                alt=""
              />
            </div>
          </template>
          <template v-slot:item.description="{ item }">
            <div class="container-max-250">
              {{ item.description }}
            </div>
          </template>
          <template v-slot:item.courses="{ item }">
            <div class="flex-inline image-container container-max-250">
              <img
                v-for="itemIn in item.courses"
                :key="itemIn.id"
                :src="itemIn.image"
                class="image-table small align-self-center"
                alt=""
              />
            </div>
          </template>
          <template v-slot:item.status="{ item }">
            <div class="text-center">
              <span
                v-if="item.status == '1'"
                class="label label-lg label-inline label-light-success"
              >
                Activo
              </span>
              <span
                v-if="item.status != '1'"
                class="label label-lg label-inline label-light-danger"
              >
                Inactivo
              </span>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="text-center">
              <a @click="goDetail(item)" class="btn btn-icon btn-light btn-sm">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    src="media/svg/icons/Design/ZoomMinus.svg"
                  ></inline-svg>
                </span>
              </a>
              <a @click="editItem(item)" class="btn btn-icon btn-light btn-sm mx-3">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    src="media/svg/icons/Communication/Write.svg"
                  ></inline-svg>
                </span>
              </a>
              <a
                @click="deleteConfirmation(item.id)"
                v-b-modal.modal-delete
                class="btn btn-icon btn-light btn-sm"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    src="media/svg/icons/General/Trash.svg"
                  ></inline-svg>
                </span>
              </a>
            </div>
          </template>
          <template slot="no-data"> Ningún dato para mostrar </template>
        </v-data-table>
      </div>
    </div>
    <b-modal id="modal-delete" title="¿Estas seguro?">
      Los datos serán borrados de forma permanente
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button size="sm" variant="primary" @click="cancel()">
          Cancelar
        </b-button>
        <b-button size="sm" variant="danger" @click="deleteItem(), ok()">
          Aceptar
        </b-button>
      </template>
    </b-modal>
  </b-overlay>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { SET_SELECTED_TRAINING,
          GET_TRAININGS_LIST,
          DELETE_TRAINING } from "@/store/modules/trainings.module";

export default {
  name: "TrainingsList",
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 10,
      options: {},
      isLoading: false,
      isLoadingTable: false,
      idToDelete: 0,
      headers: [
        { text: "Imagen", value: "image", align: "start", sortable: false, width: 100 },
        { text: "Nombre", value: "name", align: "start", sortable: false },
        { text: "Descripción", value: "description", sortable: false },
        { text: "Cursos", value: "courses", align: "start", sortable: false },
        { text: "Estatus", value: "status", align: "center", sortable: false },
        { text: "Acciones", value: "actions", align: "center", sortable: false },
      ],
      dtSearch: ""
    };
  },
  mounted() {
    this.$store.commit(SET_SELECTED_TRAINING, null);
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Trainings", route: "#" },
      { title: "Listado de trainings" },
    ]);
  },
  methods: {
    goNewItem() {
      this.$router.push("trainings-form");
    },
    goDetail(item) {
      this.$store.commit(SET_SELECTED_TRAINING, item);
      this.$router.push("trainings-detail");
    },
    deleteConfirmation(id) {
      this.idToDelete = id;
    },
    deleteItem() {
      let scope = this
      this.$store.dispatch(DELETE_TRAINING, {
        id: this.idToDelete
      }).then(() => {
        scope.$store.dispatch(GET_TRAININGS_LIST, {
          page: scope.currentPage,
          itemsPerPage: scope.itemsPerPage,
          search: this.dtSearch
        })
      })
    },
    editItem(item) {
      this.$store.commit(SET_SELECTED_TRAINING, item);
      this.$router.push("trainings-form");
    },
    getTrainingList(dataPagination){
      let scope = this
      scope.isLoading = true
      this.$store.dispatch(GET_TRAININGS_LIST, {
        page: dataPagination.page,
        itemsPerPage: dataPagination.itemsPerPage,
        search: this.dtSearch
      }).then(() => {
        scope.isLoading = false
      })
    }
  },
  computed: {
    totalRows() {
      return this.$store.state.trainings.trainingsListTotal;
    },
    list() {
      return this.$store.state.trainings.trainingsList;
    },
  },
  watch: {
    options: {
      handler (dataPagination) {
        this.getTrainingList(dataPagination)
      },
      deep: true,
    },
    dtSearch(){
      this.getTrainingList({
        page: 1,
        itemsPerPage: this.itemsPerPage
      })
    }
  },
};
</script>

<style>
  .v-data-footer__select .v-select{
    padding: 15px!important;
  }
</style>
